html,
body,
#root {
	height: 100%;
	max-height: 100%;
}
.App {
	text-align: left;
	display: flex;
	flex-direction: space-between;
	padding: 1rem 2rem;
	max-height: 100%;
}

.App > nav {
	width: 25%;
	padding-right: 2rem;
	position: fixed;
}

.App > nav > ul {
	margin: 0;
	padding: 0;
}
.App > nav > ul > li {
	list-style-type: none;
	margin: 5px;
	padding: 0;
}
.App > nav > ul > li > button {
	text-align: left;
}

.App > section {
	width: 100%;
	max-height: 100%;
	padding: 0 2rem 2rem calc(25% + 2rem);
	padding-top: 5rem;
}
.App > section > article {
	border-bottom: 1px solid #666;
	margin-bottom: 3rem;
}
.App > section h1 {
	background-color: white;
	border-bottom: 1px solid #666;
	width: 100%;
	position: fixed;
	margin-top: -6rem;
	padding: 1rem 0 1rem 0;
}
.App > section h2 {
	font-size: 1.5rem;
	cursor: pointer;
}
.App > section h2,
.App > section h4,
.App > section h5 {
	margin: 0;
	padding: 0;
}
.App > section h5 {
	font-weight: normal;
	margin-bottom: 2rem;
}
.App > section p {
	margin: 0 0 1rem 0;
}
.App > section .suggestions {
	margin: -2rem 0 2rem;
	padding: 0 0 0 15px;
}
